
<template>
  <div class="home-page">
    <myHead></myHead>
    <div class="body">
      <div class="banner">
        <img src="../assets/privacybg.png" alt="">
        <div class="fixed">
          <div class="title">Aviso de privacidad</div>
          <div class="info">Protegemos tu privacidad</div>
        </div>
      </div>
      <div class="content">
        <div class="box">
          <div class='title'>POLÍTICA DE PRIVACIDAD</div>
          <div>Esta declaración de privacidad detalla la manera en que CrediVari, junto con nuestras subsidiarias, el grupo al que pertenecemos, así como las subsidiarias de nuestro grupo (conjuntamente referidos en ocasiones como "CrediVari" o "nosotros"), recopila y procesa la información personal. En este contexto, el término "datos personales" abarca cualquier información relacionada con un individuo que permita su identificación a partir de esos datos o de la combinación de estos con otra información a la que nuestra organización tenga o pueda tener acceso, sin importar su formato.</div>
          <div>Al hacer uso de CrediVari o de cualquier aplicación o software que pongamos a disposición periódicamente (colectivamente denominados nuestras "Aplicaciones"), usted acepta y consiente las prácticas detalladas a continuación.</div>
          <div class='title1'>1. Recopilación de información</div>
          <div>1.1 Para los fines descritos en la cláusula 2, podemos recopilar y procesar la siguiente información sobre usted:</div>
          <div>(a) información que nos proporciona al completar formularios en nuestro sitio web o aplicaciones (que pueden incluir su nombre, número de identificación, dirección, dirección de correo electrónico, número de teléfono, información de tarjeta de crédito, información financiera y otros datos personales), o contactando nosotros (por teléfono, correo electrónico o de otro modo), por ejemplo;</div>
          <div>(i) cuando se registra para obtener una cuenta con nosotros en nuestros Sitios y Aplicaciones;</div>
          <div>(ii) cuando se registra para obtener una cuenta con nosotros en nuestros Sitios y Aplicaciones;</div>
          <div>(iii) cuando nos informe de cualquier problema;</div>
          <div>(iv) cuando utiliza ciertas funciones en nuestro sitio web o aplicación; o</div>
          <div>(v) Cuando completa cualquier encuesta o cuestionario que le enviamos.</div>
          <div>(b) Información que recopilamos sobre usted: información recopilada automáticamente cuando visita nuestro sitio web o utiliza nuestras aplicaciones, como por ejemplo:</div>
          <div>(i) Información técnica, incluida la dirección de Protocolo de Internet (IP) utilizada para conectar su computadora a Internet y su información de inicio de sesión, tipo y versión del navegador, configuración de zona horaria, tipos y versiones de complementos del navegador, sistema operativo y plataforma;</div>
          <div>(ii) Información sobre su visita, incluido el Localizador uniforme de recursos (URL) completo, el flujo de clics hacia, a través y desde nuestro sitio web (incluida la fecha y la hora), los productos que vio o buscó, los tiempos de respuesta de la página, los errores de descarga y la duración de la visita. a ciertas páginas, información de interacción de la página (como desplazamiento, clics y desplazamientos del mouse), métodos utilizados para salir de la página y cualquier número de teléfono utilizado para llamar a nuestro número de servicio al cliente; y</div>
          <div>(c) Información que recibimos de terceros: trabajamos con terceros de quienes podemos recibir información sobre usted, como socios comerciales, subcontratistas de servicios técnicos, de pago y de entrega, redes de publicidad, proveedores de análisis, proveedores de información de búsqueda o referencias crediticias. agencias.</div>
          <div>(d) Grabaciones de llamadas de voz y comunicaciones electrónicas con usted.</div>
          <div>1.2 Solo conservamos datos personales durante el tiempo necesario y de conformidad con las leyes y regulaciones. Los datos podrán ser archivados mientras se mantenga la finalidad para la que fueron utilizados.</div>
          <div class='title1'>2. Propósito de recopilar, usar y divulgar su información</div>
          <div>2.1Con el propósito de mejorar nuestros servicios y brindarle la mejor experiencia posible, solicitamos su consentimiento para la recolección y utilización de ciertos datos personales. Estos datos son gestionados de manera responsable y protegidos a través de procesos seguros de transmisión a nuestros servidores:</div>
          <div>(i) <b>SMS:</b> Recopilamos mensajes SMS, pero únicamente procesamos aquellos relacionados con información financiera relevante. Esta información es fundamental para evaluar su situación financiera y determinar su puntuación de crédito, lo que nos permite ofrecerle los mejores préstamos disponibles. Los datos se transmiten de manera segura a través de <b style={{color: '#0070c0'}}>https://external-api.credivari.com</b></div>
          <div>(ii) <b>Registros de Llamadas:</b> Requerimos acceso a su registro de llamadas para verificar la instalación de la aplicación en su dispositivo y proporcionarle una verificación segura de contraseña. Los datos se transmiten de manera segura a través de <b style={{color: '#0070c0'}}>https://external-api.credivari.com</b></div>
          <div>(iii) <b>Contactos:</b> Solicitamos sus 2 contactos de emergencia para verificar su identidad y gestionar riesgos. Los datos se transmiten de manera segura a través de <b style={{color: '#0070c0'}}>https://external-api.credivari.com</b></div>
          <div>(iv) <b>Cámara:</b> Utilizamos la cámara para verificar su identidad y prevenir el robo de información.</div>
          <div>(v) <b>Ubicación Aproximada:</b> Recolectamos información de ubicación para garantizar que se encuentra dentro del territorio correspondiente para utilizar nuestros servicios. Los datos se transmiten de manera segura a través de <b style={{color: '#0070c0'}}>https://external-api.credivari.com</b></div>
          <div>(vi) <b>Lista de Instalación de la Aplicación:</b> Recopilamos información sobre las aplicaciones instaladas en su dispositivo para evaluar su capacidad de pago y reducir el riesgo crediticio. Los datos se transmiten de manera segura a través de <b style={{color: '#0070c0'}}>https://external-api.credivari.com</b></div>
          <div>(vii) <b>Datos del Portapapeles:</b> Recopilamos datos del portapapeles relacionados con transacciones financieras para evaluar su solvencia y prevenir el fraude. Los datos se transmiten de manera segura a través de <b style={{color: '#0070c0'}}>https://external-api.credivari.com</b></div>
          <div>(viii) <b>Datos del Dispositivo:</b> Recopilamos información específica sobre su dispositivo para identificarlo y prevenir el fraude. Los datos se transmiten de manera segura a través de <b style={{color: '#0070c0'}}>https://external-api.credivari.com</b></div>
          <div>2.2 Podemos utilizar sus datos personales para marketing directo (es decir, para proporcionar o promocionar productos o servicios enviándole información relevante directamente). Requerimos específicamente su consentimiento para este propósito y puede optar por no participar en cualquier momento. A los efectos de estos Términos:</div>
          <div>(a) Los datos personales que pueden usarse para marketing directo son datos personales que usted nos proporciona o que recopilamos de usted de acuerdo con la cláusula 1.1 anterior;</div>
          <div>(b) los tipos de servicios o productos que podrán ofrecerse o publicitarse serán los nuestros y nuestros afiliados o socios comerciales, seminarios, conferencias o eventos;</div>
          <div>(c) Es posible que se le envíe información relevante por correo electrónico, mensaje en la aplicación o mensaje telefónico o llamada telefónica;</div>
          <div>(d) Puede optar por no participar en cualquier momento enviando un correo electrónico a chat@credivari.com. Dejaremos de enviarle comunicaciones de marketing gratuitas.</div>
          <div>2.3 Usted reconoce que divulgaremos sus datos personales a nuestros socios en el proceso de brindarle servicios, y nuestros socios utilizarán sus datos personales con el fin de brindarle servicios de préstamo, incluidos, entre otros:</div>
          <div>(a) Verificar su identidad;</div>
          <div>(b) recuperar datos e informes de cualquier agencia de crédito, incluida una o más recuperaciones de su saldo;</div>
          <div>(c) generar ofertas de préstamos personalizadas para usted;</div>
          <div>(d) contactarlo para finalizar los términos del préstamo y organizar la ejecución de los documentos del préstamo;</div>
          <div>o (e) cualquier otro propósito directamente relacionado o incidental con lo anterior, en cuyo caso usted reconoce que sus datos personales estarán sujetos a las respectivas políticas de privacidad de nuestros socios.</div>
          <div>2.4 Las grabaciones de llamadas de voz y comunicaciones electrónicas con usted pueden usarse con fines de capacitación y análisis.</div>
          <div class='title1'>3. Divulgación de su información</div>
          <div>3.1 Mantendremos la confidencialidad de sus datos personales, pero usted acepta que podemos proporcionar la información a:</div>
          <div>(a) cualquier miembro de nuestro grupo, siendo nuestras subsidiarias, nuestra sociedad controladora final y sus subsidiarias, tal como se define en la sección 5 de la Ley de Sociedades Anónimas (Capítulo 50);</div>
          <div>(b) personas, agentes, consultores, auditores, contratistas, instituciones financieras y proveedores de servicios (tales como aquellos dedicados a brindar servicios de soporte) asociados con nuestras operaciones o servicios;</div>
          <div>(c) nuestras oficinas en el extranjero, afiliados, socios comerciales y pares (solo cuando sea necesario);</div>
          <div>(d) nuestros socios (incluidas instituciones financieras y bancos autorizados), excluido HSBC;</div>
          <div>(e) personas que tienen el deber de confidencialidad hacia nosotros;</div>
          <div>(f) personas a quienes estamos obligados a revelar información según las leyes y regulaciones aplicables;</div>
          <div>3.2 Haremos esfuerzos razonables para garantizar que cualquier tercero que reciba sus datos personales de nuestra parte procese dichos datos personales de acuerdo con las leyes y regulaciones aplicables.</div>
          <div>3.3 Los datos que recopilamos sobre usted pueden transferirse y almacenarse en un destino fuera de la región en la que operamos. Esta información también podrá ser procesada por personas que operen en el extranjero como se menciona en la cláusula 3 anterior. En este caso, se aplicarán las siguientes disposiciones:</div>
          <div>(a) Si transferimos sus datos personales a un país fuera de Singapur, obtendremos su consentimiento para dicha transferencia y tomaremos medidas para garantizar que sus datos personales continúen recibiendo una protección que sea al menos equivalente a los estándares existentes. Estándares proporcionados bajo PDPA ;</div>
          <div>(b) Al enviar sus datos personales, usted acepta la transferencia, el almacenamiento o el procesamiento de los datos en el extranjero. Tomaremos todas las medidas razonablemente necesarias para garantizar que sus datos se procesen de forma segura y de acuerdo con esta política de privacidad; ·v</div>
          <div class='title1'>4.Cookies</div>
          <div>4.1 Nuestro sitio web utiliza cookies para distinguirlo de otros usuarios del sitio web. Esto nos ayuda a brindarle una buena experiencia cuando navega por nuestro sitio web y también nos permite mejorarlo.</div>
          <div>4.2 Una cookie es un pequeño archivo de letras y números que almacenamos en su navegador o en el disco duro de su computadora si acepta el uso de cookies. Las cookies contienen información que se transfiere al disco duro de su computadora.</div>
          <div>4.3 Utilizamos cookies persistentes y cookies de sesión. Una cookie persistente permanece en su navegador y la leemos cuando regresa a nuestro sitio web o a un sitio web asociado que utiliza nuestros servicios. Una cookie de sesión dura solo mientras dura la sesión (normalmente la visita actual al sitio web o la sesión del navegador).</div>
          <div>4.4 Utilizamos las siguientes cookies:</div>
          <div>(a) Cookies estrictamente necesarias: son cookies necesarias para el funcionamiento de nuestro sitio web. Por ejemplo, incluyen cookies que le permiten iniciar sesión en áreas seguras de nuestro sitio web, utilizar un carrito de compras o utilizar servicios de facturación electrónica.</div>
          <div>(b) Cookies analíticas/de rendimiento: nos permiten reconocer y contar el número de visitantes y comprender cómo se mueven los visitantes por nuestro sitio web cuando lo utilizan. Esto nos ayuda a mejorar el funcionamiento de nuestro sitio web, por ejemplo, garantizando que los usuarios encuentren fácilmente lo que buscan.</div>
          <div>(c) Cookies de funcionalidad: estas cookies se utilizan para identificarlo cuando regresa a nuestro sitio web. Esto nos permite personalizar nuestro contenido para usted, dirigirnos a usted por su nombre y recordar sus preferencias.</div>
          <div>(d) Cookies de orientación: estas cookies registran su visita a nuestro sitio web, las páginas que ha visitado y los enlaces que ha seguido. Utilizaremos esta información para hacer que nuestro sitio web y la información que se muestra en él sean más relevantes para sus intereses.</div>
          <div>4.5 Puedes bloquear las cookies activando la configuración en tu navegador que te permite rechazar la instalación de todas o algunas de las cookies. Sin embargo, si hace esto, es posible que no pueda acceder a todo o parte de nuestro sitio web.</div>
          <div>4.6 Podemos utilizar servicios web de terceros en nuestro sitio web. Los proveedores de servicios que administran estos servicios utilizan tecnologías como cookies (que pueden ser cookies analíticas/de rendimiento o cookies de orientación), registros del servidor web y balizas web para ayudarnos a analizar cómo los visitantes usan nuestro sitio web y hacer que la información se muestre en el sitio web. Mas claro. Relevante para sus intereses. La información recopilada a través de estos medios, incluidas las direcciones IP, se divulgará a estos proveedores de servicios. Estos servicios de análisis pueden utilizar los datos recopilados para contextualizar y personalizar los materiales de marketing de sus propias redes publicitarias.</div>
          <div class='title1'>5. Sitios web de terceros</div>
          <div>5.1 Nuestro sitio web, nuestras aplicaciones o nuestras comunicaciones con usted pueden contener de vez en cuando enlaces a sitios web de terceros sobre los que no tenemos control. Si sigue un enlace a cualquiera de estos sitios, tenga en cuenta que tienen sus propias prácticas y políticas. Le recomendamos que lea las políticas o declaraciones de privacidad de estos sitios web para comprender sus derechos. No somos responsables de ninguna práctica de sitios web de terceros.</div>
          <div class='title1'>6. Seguridad</div>
          <div>6.1 Toda la información que nos proporciona se almacena en nuestros servidores seguros.</div>
          <div>6.2 Limitamos estrictamente el acceso a la información personal a nuestros empleados, proveedores de servicios y contratistas según sea necesario y nos aseguramos de que este personal cumpla con las obligaciones contractuales de confidencialidad.</div>
          <div>6.3 Revisamos nuestras prácticas de recopilación, almacenamiento y procesamiento de información de vez en cuando para evitar el acceso, procesamiento o uso no autorizados.</div>
          <div>6.4 Sin embargo, tenga en cuenta que la transmisión de información a través de Internet no es completamente segura. Aunque haremos todo lo posible para proteger sus datos personales, no podemos garantizar la seguridad de sus datos transmitidos a nuestro sitio web; cualquier transmisión es bajo su propio riesgo.</div>
          <div class='title1'>7. Tus derechos</div>
          <div>7.1 Tienes derecho a:</div>
          <div>(a) comprobar si conservamos sus datos personales;</div>
          <div>(b) acceder a cualquier dato personal que tengamos sobre usted;</div>
          <div>(c) exigirnos que corrijamos cualquier inexactitud o error en cualquier dato personal que tengamos sobre usted.</div>
          <div>7.2 Cualquier solicitud realizada según la cláusula 7.1 puede estar sujeta a una pequeña tarifa administrativa para cubrir nuestros costos de procesamiento de su solicitud.</div>
          <div class='title1'>8. Cambios en nuestra política de privacidad</div>
          <div>8.1 Podemos modificar, cambiar o enmendar esta Política de Privacidad de vez en cuando a nuestro exclusivo criterio sin previo aviso y publicaremos la Política de Privacidad actualizada en nuestro sitio web. Al continuar utilizando nuestros Sitios y Aplicaciones después de que los cambios entren en vigencia, usted acepta estar sujeto a la Política de Privacidad revisada.</div>
          <div class='title1'>9. Contáctanos</div>
          <div>9.1 Si tiene alguna pregunta, comentario o solicitud con respecto a los datos personales, comuníquese con nuestro Oficial de Protección de Datos en <b class='blue'>chat@credivari.com</b>. Añade como asunto del correo electrónico "Al Delegado de Protección de Datos".</div>
          <div class='title1'>10. Ley aplicable</div>
          <div>10.1 Esta Política de Privacidad se regirá e interpretará de conformidad con las leyes de Chile y Peru.</div>
        </div>
      </div>
    </div>
    <myFoot />
    <!-- <goTop /> -->
  </div>
</template>

<script>
import myHead from "@/components/header";
import myFoot from "@/components/footer";
// import goTop from "@/components/goTop";

export default {
  data() {
    return {
      current: '1'
    };
  },
  created() {
    
  },
  methods: {

  },
  components: {
    myHead,
    myFoot
  },
};
</script>

<style lang="scss" scoped>
.home-page {
  padding-top: 80px;
}
.banner {
  width: 100%;
  position: relative;

  img {
    width: 100%;
  }
  .fixed {
    position: absolute;
    left: 10%;
    top: 30%;
    .title {
      font-size: 63px;
      font-family: YuMin-Demibold, YuMin;
      font-weight: 600;
      color: #333333;
      line-height: 95px;
      letter-spacing: 1px;
    }
    .info {
      font-size: 30px;
      font-family: AvenirNext-Medium, AvenirNext;
      font-weight: 500;
      color: #13254A;
      line-height: 30px
    }
  }
  
}
.content {
  width: 90%;
  margin: 0 auto;
  padding: 50px 0px 100px 0px;

  .box {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 15px;
    padding: 20px;
    box-sizing: border-box;

    .clearfix:before,
    .clearfix:after {
      display: table;
      content: "";
      line-height: 0;
      clear: both;
    }
    .wrapper {
      padding: 0 16px;
    }
    @media screen and (min-width: 450px) {
      .wrapper {
        width: 1050px;
        margin: 0 auto;
      }
    }

    h1 {
      text-align: center;
    }

    .title {
      font-size: 20px;
      color: #333;
      font-weight: bold;
      text-align: center;
    }

    .title1 {
      font-weight: bold;
      font-size: 20px;
    }

    dd {
      margin-inline-start: 0;
    }

    .indent {
      text-indent: 2em;
    }

    p,
    div,
    li {
      font-size: 16px;
      line-height: 24px;
      margin-block-start: 0;
      margin-block-end: 0;
      font-weight: 300;
      font-family: PingFang SC, -apple-system, BlinkMacSystemFont, Helvetica Neue,
        Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei,
        sans-serif;
    }
    li {
      list-style: disc;
    }
  }
}
::v-deep .el-collapse-item__header {
    font-size: 20px !important;
    font-weight: bold;
  }
</style>
