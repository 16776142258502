<template>
  <div class="home-page">
    <myHead></myHead>
    <div class="body">
      <div class="banner">
        <img src="../assets/homebg.png" alt="">
        <div class="fixed">
          <div>
            <span class="tit bold">CrediVari</span>
            <span class="tit">Préstamos de Dinero</span>
          </div>
          <div class="info">CrediVari utiliza inteligencia artificial y tecnología de control de riesgos de big data para brindar a los usuarios servicios de crédito financiero convenientes, rápidos y seguros</div>
          <div class="btn">
            <input v-model="amount" type="text">
            <a href="#" target="_blank">Descargar ahora</a>
          </div>
        </div>
      </div>
      <div class="box1">
        <div class="left">
          <img src="../assets/homebg2.png" alt="">
        </div>
        <div class="right">
          <div class="tit">Fácil de aplicar，préstamo de tres pasos</div>
          <div class="info">solo necesitas tu Original de Cédula de Ciudadanía y Cuenta Bancaria Personal.</div>
          <div class="tit">Tasa de interés ultra baja,cuota flexible</div>
          <div class="info">Aplicación flexible para el monto del préstamo, compatible con múltiples métodos de pago</div>
          <div class="tit">Las tasas de interés son claras,pide prestado con tranquilidad</div>
          <div class="info">La información personal se cifra durante todo el proceso para</div>
        </div>
      </div>
      <div class="box2">
        <div class="div1">
          <div class="title">Pasos para solicitar un préstamo</div>
        </div>
        <div class="div2"></div>
        <div class="fixed">
          <img src="../assets/img1.png" alt="">
          <img src="../assets/img2.png" alt="">
          <img src="../assets/img3.png" alt="">
          <img src="../assets/img4.png" alt="">
        </div>
      </div>
      <div class="box3">
        <div class="title">Contácteno</div>
        <div class="info">Si tiene alguna pregunta, póngase en contacto con nuestro servicio de atención al cliente</div>
        <div class="itembox">
          <div class="item">
            <img src="../assets/phone.png" alt="">
            <div class="tit">Teléfono</div>
            <!-- <div class="txt">+593 984743847</div> -->
            <div class="txt">Mon - Vie 10am - 6pm</div>
          </div>
          <div class="item">
            <img src="../assets/email.png" alt="">
            <div class="tit">Correo</div>
            <div class="txt">chat@credivari.com</div>
            <div class="txt">7*24 horas en línea</div>
          </div>
          <div class="item">
            <img src="../assets/whatsapp.png" alt="">
            <div class="tit">WhatsApp</div>
            <!-- <div class="txt">+593 984743847</div> -->
            <div class="txt">Mon - Vie 10am - 6pm</div>
          </div>
        </div>
      </div>
    </div>
    <myFoot />
    <!-- <goTop /> -->
  </div>
</template>

<script>
import myHead from "@/components/header";
import myFoot from "@/components/footer";
// import goTop from "@/components/goTop";

export default {
  data() {
    return {
      current: '1',
      amount: '&5000'
    };
  },
  created() {
    
  },
  methods: {

  },
  components: {
    myHead,
    myFoot
  },
};
</script>

<style lang="scss" scoped>
.home-page {
  padding-top: 80px;
}
.banner {
  width: 100%;
  position: relative;
  text-align: right;
  position: relative;

  > img {
    width: 789px;
    height: 504px;
    margin: 116px 0px 40px;
  }
  .fixed {
    position: absolute;
    left: 104px;
    top: 50%;
    transform: translateY(-50%);
    text-align: left;
    .tit {
      height: 40px;
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;

      &.bold {
        font-size: 50px;
        font-family: YuMin-Demibold, YuMin;
        font-weight: 600;
        color: #333333;
        line-height: 75px;
        letter-spacing: 1px;
        margin-right: 10px;
      }
    }
    .info {
      width: 518px;
      font-size: 20px;
      font-family: AvenirNext-Medium, AvenirNext;
      font-weight: 500;
      color: #13254A;
      line-height: 30px;
      margin-top: 46px;
    }

    .btn {
      display: flex;
      margin-top: 21px;
      input {
        width: 285px;
        height: 56px;
        border: 1px solid #DDDDDD;
        outline-style: 1px solid #3D77FA;
        padding-left: 20px;
        box-sizing: border-box;
        color: #979eae;
      }
      a {
        width: 235px;
        height: 56px;
        color: #fff;
        background-color: #3D77FA;
        text-align: center;
        line-height: 56px;
        // border-radius: 5px;
      }
    }
  }
}
.box1 {
  display: flex;
  padding: 0px 50px;
  margin-top: 50px;
  margin-bottom: 50px;
  
  .left {
    width: 50%;
    img {
      width: 100%;
    }
  }
  .right {
    width: 50%;
    padding-left: 70px;
    box-sizing: border-box;
    .tit {
      font-size: 24px;
      font-family: Silom;
      color: #020248;
      line-height: 46px;
    }
    .info {
      font-size: 20px;
      font-family: AvenirNext-Regular, AvenirNext;
      font-weight: 400;
      color: #13254A;
      line-height: 30px;
      margin-bottom: 30px;
    }
  }
}
.box2 {
  width: 100%;
  position: relative;
  .div1 {
    width: 100%;
    height: 445px;
    background-color: #3D77FA;
    overflow: hidden;
    
    .title {
      text-align: center;
      font-size: 32px;
      font-family: Silom;
      color: #FFFFFF;
      line-height: 46px;
      margin-top: 100px;
    }
  }
  .div2 {
    width: 100%;
    height: 186px;
    background-color: #f5f6fa;
  }
  .fixed {
    width: 100%;
    position: absolute;
    left: 0px;
    bottom: 0px;
    display: flex;

    img {
      width: 25%;
    }
  }
}
.box3 {
  width: 100%;
  padding: 50px;
  box-sizing: border-box;
  background-color: #fff;
  padding-top: 30px;
  .title {
    font-size: 32px;
    font-family: Silom;
    color: #3D77FA;
    text-align: center;
    font-weight: bold;
    line-height: 50px;
    letter-spacing: 1px;
  }
  .info {
    font-size: 21px;
    font-family: Helvetica;
    color: #5D5D5B;
    line-height: 32px;
    text-align: center;
  }
  .itembox {
    margin-top: 30px;
    display: flex;
    justify-content: space-around;
    .item {
      width: 300px;
      padding: 20px 40px 30px 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      border-radius: 10px;
      box-shadow: 0px 0px 30px 1px #ebeaea;

      img {
        width: 50px;
        margin-top: 20px;
      }
      .tit {
        font-size: 18px;
        color: #272727;
        font-weight: bold;
        margin: 20px 0px;
      }
      .txt {
        font-size: 18px;
        color: rgba(39,39,39,0.6);
        line-height: 30px;
        margin-top: 20px;
      }
    }
  }
}
</style>
