<template>
  <div class="home-page">
    <myHead></myHead>
    <div class="body">
      <div class="banner">
        <img src="../assets/faqbg.png" alt="">
        <div class="fixed">
          <div class="title">CrediVari</div>
          <div class="info">Sobre nosotros</div>
        </div>
      </div>
      <div class="content">
        <div class="box">
          <div>CrediVari, una empresa líder en servicios financieros, se complace en presentar su innovador producto de préstamos diseñado para satisfacer las necesidades financieras de nuestros clientes. Nuestro compromiso es ofrecer soluciones flexibles y accesibles para ayudarle a alcanzar sus metas y superar desafíos financieros.</div>
          <div>&nbsp;</div>
          <div class='title1'>Características Clave:</div>
          <div>&nbsp;</div>
          <div class='indent'><b>1.Préstamos Personalizados:</b> En CrediVari entendemos que cada cliente tiene necesidades financieras únicas. Por ello, ofrecemos préstamos personalizados adaptados a sus circunstancias específicas, ya sea para gastos inesperados, proyectos personales o consolidación de deudas.</div>
          <div>&nbsp;</div>
          <div class='indent'><b>2.Proceso Rápido y Sencillo:</b> Nos enorgullece contar con un proceso de solicitud rápido y sencillo. A través de nuestra plataforma en línea, los clientes pueden completar la solicitud en pocos pasos, agilizando así la aprobación y desembolso de fondos.</div>
          <div>&nbsp;</div>
          <div class='indent'><b>3.Tasas Competitivas:</b> En CrediVari, buscamos ofrecer tasas de interés competitivas que se ajusten a su presupuesto. Nuestro objetivo es proporcionar opciones financieras asequibles para que pueda cumplir con sus obligaciones de pago de manera cómoda.</div>
          <div>&nbsp;</div>
          <div class='indent'><b>4.Transparencia y Confidencialidad:</b> Valoramos la transparencia y la confianza. Nuestra política es proporcionar información clara sobre los términos y condiciones de nuestros préstamos, asegurando que nuestros clientes comprendan completamente las implicaciones financieras.</div>
          <div>&nbsp;</div>
          <div class='indent'><b>5.Atención al Cliente Personalizada:</b> En CrediVari, nos esforzamos por ofrecer un servicio al cliente excepcional. Nuestro equipo está disponible para brindar asistencia y responder a cualquier pregunta que pueda tener durante todo el proceso, desde la solicitud hasta la liquidación del préstamo.</div>
          <div>&nbsp;</div>
          <div class='title1'>Cómo Solicitar:</div>
          <div>&nbsp;</div>
          <div class='indent'><b>1.Registro en Línea:</b> Cree una cuenta en nuestra plataforma en línea proporcionando la información necesaria.</div>
          <div>&nbsp;</div>
          <div class='indent'><b>2.Solicitud de Préstamo:</b> Complete el formulario de solicitud indicando el monto y plazo deseados.</div>
          <div>&nbsp;</div>
          <div class='indent'><b>3.Revisión y Aprobación:</b> Nuestro equipo revisará su solicitud y le informará sobre la aprobación en el menor tiempo posible.</div>
          <div>&nbsp;</div>
          <div class='indent'><b>4.Desembolso de Fondos:</b> Una vez aprobado, los fondos se transferirán a su cuenta para que pueda utilizarlos según sus necesidades.</div>
          <div>&nbsp;</div>
          <div>En CrediVari, nos esforzamos por ser su socio confiable en el camino hacia el logro de sus metas financieras. Descubra las posibilidades que nuestros préstamos pueden ofrecerle y dé el siguiente paso hacia un futuro financiero más sólido. ¡CrediVari, su solución de préstamos confiable!</div>
          <div>&nbsp;</div>
        </div>
      </div>
    </div>
    <myFoot />
    <!-- <goTop /> -->
  </div>
</template>

<script>
import myHead from "@/components/header";
import myFoot from "@/components/footer";
// import goTop from "@/components/goTop";

export default {
  data() {
    return {
      current: '1'
    };
  },
  created() {
    
  },
  methods: {

  },
  components: {
    myHead,
    myFoot
  },
};
</script>

<style lang="scss" scoped>
.home-page {
  padding-top: 80px;
}
.banner {
  position: relative;

  img {
    width: 100%;
  }
  .fixed {
    position: absolute;
    left: 10%;
    top: 30%;
    .title {
      font-size: 63px;
      font-family: YuMin-Demibold, YuMin;
      font-weight: 600;
      color: #333333;
      line-height: 95px;
      letter-spacing: 1px;
    }
    .info {
      font-size: 30px;
      font-family: AvenirNext-Medium, AvenirNext;
      font-weight: 500;
      color: #13254A;
      line-height: 30px
    }
  }  
}
.content {
  width: 90%;
  margin: 0 auto;
  padding: 50px 0px 100px 0px;

  .box {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 15px;
    padding: 20px;
    box-sizing: border-box;
    
    .clearfix:before,
    .clearfix:after {
      display: table;
      content: "";
      line-height: 0;
      clear: both;
    }
    .wrapper {
      padding: 0 16px;
    }
    @media screen and (min-width: 450px) {
      .wrapper {
        width: 1050px;
        margin: 0 auto;
      }
    }

    h1 {
      text-align: center;
    }

    .title {
      font-size: 20px;
      color: #333;
      font-weight: bold;
      text-align: center;
    }

    .title1 {
      font-weight: bold;
      font-size: 14px;
    }

    dd {
      margin-inline-start: 0;
    }

    .indent {
      text-indent: 2em;
    }

    p,
    div {
      font-size: 16px;
      line-height: 24px;
      margin-block-start: 0;
      margin-block-end: 0;
      font-weight: 300;
      font-family: PingFang SC, -apple-system, BlinkMacSystemFont, Helvetica Neue,
        Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei,
        sans-serif;
    }
  }
}
::v-deep .el-collapse-item__header {
  font-size: 20px !important;
  font-weight: bold;
}
</style>
