<template>
  <div class="header-com">
    <div class="wrap">
      <div class="left-area">
        <img class="img" src="../assets/logo2.png" />
        <div class="logo">CrediVari</div>
      </div>
      <div class="right-area">
        <router-link class="item" tag="li" :class="path == '/home'? 'current' : '' " to="/home">Inicio</router-link>
        <router-link class="item" tag="li" :class="path == '/aboutus'? 'current' : '' " to="/aboutus">Sobre nosotros</router-link>
        <router-link class="item" tag="li" :class="path == '/privacy'? 'current' : '' " to="/privacy">Aviso de privacidad</router-link>
        <router-link class="item" tag="li" :class="path == '/user'? 'current' : '' " to="/user">Términos y condicion</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:['stylehead'],
  data() {
    return {
      currentData:[
      ],
      selectFlag:false,
      path: '',
    };
  },
  mounted(){
    this.path = this.$route.path
  },
  methods: {
    
  }
};
</script>

<style lang="scss" scoped>
.header-com {
  width: 100%;
  position: fixed;
  top: 0px;
  padding: 0px 100px;
  box-sizing: border-box;
  height: 80px;
  background: #f6f8ff;
  z-index: 99;
  box-shadow: 0px 3px 8px 0px rgba(178,178,178,0.5);
  .wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;

    .left-area {
      width: 200px;
      height: 100%;
      line-height: 50px;
      display: flex;
      align-items: center;

      img {
        width: 40px;
        height: 40px;
      }
      .logo {
        margin-left: 10px;
        font-weight: bold;
        font-size: 22px;
        font-family: Helvetica-Bold, Helvetica;
        letter-spacing: 0.11111rem;
      }
    }
    .right-area {
      height: 100%;
      display: flex;
      align-items: center;
      
      .item {
        height: 38px;
        font-family: Arial-BoldMT, Arial;
        color: #827a76;
        line-height: 38px;
        margin: 0px 30px;
        cursor: pointer;
        position: relative;

        &.current {
          font-weight: bold;
          color: #272727;
        }

      }
    }
  }
}
</style>