<template>
  <div class="footer-com">
    <div class="content">
      <div class="left">
        <img src="../assets/logo2.png" alt="">
        <div class="info">
          <div class="tit">CrediVari</div>
          <div class="txt">App profesional de préstamos en línea en Chile</div>
        </div>
      </div>
      <div class="right">
        <a href="javascript:;" target="_blank">Préstamo ahora</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["positive"],
  data() {
    return {};
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.footer-com {
  width: 100%;
  height: 200px;
  background: #597dea;
  .content {
    width: 100%;
    height: 100%;
    padding: 0px 50px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .left {
      display: flex;
      img {
        width: 50px;
      }
      .info {
        margin-left: 10px;
        .tit {
          font-size: 24px;
          font-family: Helvetica-Bold, Helvetica;
          font-weight: bold;
          color: #FFFFFF;
          letter-spacing: 2px;
        }
        .txt {
          font-size: 18px;
          font-family: ArialMT;
          color: rgba(255,255,255,0.67);
        }
      }
    }
    .right {
      width: 200px;
      height: 50px;
      font-size: 18px;
      font-family: SanFranciscoDisplay-Regular, SanFranciscoDisplay;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 50px;
      text-align: center;
      border: 1px solid #FFFFFF;
      border-radius: 5px;
      cursor: pointer;
      margin-right: 200px;
      a {
        color: #fff;
      }
    }
  }
}
</style>