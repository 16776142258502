
<template>
  <div class="home-page">
    <myHead></myHead>
    <div class="body">
      <div class="banner">
        <img src="../assets/userbg.png" alt="">
        <div class="fixed">
          <div class="title">Acuerdo del Usuario</div>
          <div class="info">Comenzar nuestra cooperación</div>
        </div>
      </div>
      <div class="content">
        <div class="box">
          <div class='title'>ACUERDO DEL USUARIO</div>
          <div>Bienvenido/a a CrediVari (en adelante, "la Aplicación"). Por favor, lea detenidamente y comprenda este Acuerdo del Usuario (en adelante, "el Acuerdo") antes de utilizar la Aplicación. El uso de la Aplicación indica su acuerdo con todos los términos de este Acuerdo. Si no está de acuerdo con algún contenido de este Acuerdo, por favor, deje de utilizar la Aplicación.</div>
          <div class='title1'>1. Aceptación de los Términos</div>
          <div>1.1 Al descargar, instalar o utilizar la Aplicación, usted acepta quedar sujeto a los términos de este Acuerdo.</div>
          <div>1.2 Si está utilizando la Aplicación en representación de una entidad legal u otra organización, usted declara y garantiza que tiene la autoridad para obligar a esa entidad u organización a cumplir con este Acuerdo.</div>
          <div class='title1'>2. Contenido del Servicio</div>
          <div>2.1 La Aplicación ofrece servicios de préstamos financieros a los usuarios.</div>
          <div>2.2 Usted comprende y acepta que algunas funciones de la Aplicación pueden requerir conexión a Internet, y será responsable de los costos asociados con la conexión.</div>
          <div class='title1'>3. Cuenta de Usuario</div>
          <div>3.1 Al utilizar ciertas funciones o servicios de la Aplicación, es posible que necesite crear una cuenta de usuario. Usted debe asegurarse de que la información de registro proporcionada sea veraz, precisa, completa y esté actualizada.</div>
          <div>3.2 Usted es responsable de mantener la confidencialidad de su cuenta, así como de la seguridad de su contraseña.</div>
          <div>3.3 Si nota un uso no autorizado de su cuenta, debe notificar a la Aplicación de inmediato. No nos hacemos responsables de pérdidas causadas por el uso no autorizado de su cuenta antes de notificarnos.</div>
          <div class='title1'>4. Normas de Conducta del Usuario</div>
          <div>4.1 Usted se compromete a no utilizar la Aplicación para realizar actividades ilegales o contrarias a las normas, incluyendo, pero no limitándose a:</div>
          <div>4.1.1 Crear, copiar, publicar, difundir o almacenar cualquier información ilegal, obscena, difamatoria, amenazante, calumniosa, o que viole la privacidad de terceros.</div>
          <div>4.1.2 Utilizar la Aplicación para realizar actividades fraudulentas, robo, o transacciones ilegales.</div>
          <div>4.1.3 Participar en acciones que puedan dañar la seguridad del sistema de la Aplicación, como ataques, intrusiones maliciosas, etc.</div>
          <div>4.1.4 Interferir de cualquier manera con el funcionamiento normal de la Aplicación o dañar los servidores y redes relacionados con la Aplicación.</div>
          <div>4.2 Usted acepta cumplir con todas las leyes y regulaciones aplicables, siendo totalmente responsable por sus acciones a través de la Aplicación.</div>
          <div class='title1'>5. Política de Privacidad</div>
          <div>5.1 La Aplicación recopilará, utilizará y protegerá su información personal de acuerdo con su Política de Privacidad. Lea detenidamente la Política de Privacidad antes de utilizar la Aplicación.</div>
          <div>5.2 Usted acepta que la Aplicación recopile, utilice, transmita y revele su información personal de acuerdo con la Política de Privacidad.</div>
          <div class='title1'>6. Propiedad Intelectual</div>
          <div>6.1 Todos los contenidos de la Aplicación, incluyendo, pero no limitándose a, texto, imágenes, iconos, audio, video, diseño de interfaz, son propiedad de la Aplicación.</div>
          <div>6.2 No se le permite usar, copiar, distribuir, mostrar, interpretar, vender o crear obras derivadas de ninguna manera sin el permiso expreso de la Aplicación.</div>
          <div class='title1'>7. Descargo de Responsabilidad</div>
          <div>7.1 La Aplicación no garantiza que sus servicios o funciones estén libres de interrupciones, sean oportunos, seguros, precisos, completos o sin errores.</div>
          <div>7.2 Usted comprende y acepta que el uso de la Aplicación conlleva riesgos, incluyendo, pero no limitándose a, la divulgación de información, fallos del sistema, ataques de terceros, etc. Usted asume todos los riesgos asociados con el uso de la Aplicación.</div>
          <div class='title1'>8. Cambios, Interrupción o Terminación del Servicio</div>
          <div>8.1 La Aplicación se reserva el derecho de cambiar, interrumpir o terminar parte o la totalidad de sus servicios previa notificación.</div>
          <div>8.2 En caso de que viole este Acuerdo, la Aplicación puede interrumpir o terminar sus servicios sin responsabilidad hacia usted.</div>
          <div class='title1'>9. Otras Disposiciones</div>
          <div>9.1 Este Acuerdo puede modificarse debido a cambios en leyes y regulaciones, ajustes comerciales, entre otros motivos. La versión modificada se publicará en la Aplicación para su revisión.</div>
          <div>9.2 La interpretación, ejecución y resolución de disputas de este Acuerdo se rige por las leyes de Chile. En caso de disputa, las partes deben intentar resolverla de manera amistosa; de no llegar a un acuerdo, cualquiera de las partes puede presentar una demanda en el tribunal competente.</div>
        </div>
      </div>
    </div>
    <myFoot />
    <!-- <goTop /> -->
  </div>
</template>

<script>
import myHead from "@/components/header";
import myFoot from "@/components/footer";
// import goTop from "@/components/goTop";

export default {
  data() {
    return {
      current: '1'
    };
  },
  created() {
    
  },
  methods: {

  },
  components: {
    myHead,
    myFoot
  },
};
</script>

<style lang="scss" scoped>
.home-page {
  padding-top: 80px;
}
.banner {
  position: relative;

  img {
    width: 100%;
  }
  .fixed {
    position: absolute;
    left: 10%;
    top: 30%;
    .title {
      font-size: 63px;
      font-family: YuMin-Demibold, YuMin;
      font-weight: 600;
      color: #333333;
      line-height: 95px;
      letter-spacing: 1px;
    }
    .info {
      font-size: 30px;
      font-family: AvenirNext-Medium, AvenirNext;
      font-weight: 500;
      color: #13254A;
      line-height: 30px
    }
  }
  
}
.content {
  width: 90%;
  margin: 0 auto;
  padding: 50px 0px 100px 0px;

  .box {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 15px;
    padding: 20px;
    box-sizing: border-box;
    
    .clearfix:before,
    .clearfix:after {
      display: table;
      content: "";
      line-height: 0;
      clear: both;
    }
    .wrapper {
      padding: 0 16px;
    }
    @media screen and (min-width: 450px) {
      .wrapper {
        width: 1050px;
        margin: 0 auto;
      }
    }

    h1 {
      text-align: center;
    }

    .title {
      font-size: 20px;
      color: #333;
      font-weight: bold;
      text-align: center;
    }

    .title1 {
      font-weight: bold;
      font-size: 20px;
    }

    dd {
      margin-inline-start: 0;
    }

    .indent {
      text-indent: 2em;
    }

    p,
    div,
    li {
      font-size: 16px;
      line-height: 24px;
      margin-block-start: 0;
      margin-block-end: 0;
      font-weight: 300;
      font-family: PingFang SC, -apple-system, BlinkMacSystemFont, Helvetica Neue,
        Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei,
        sans-serif;
    }
    li {
      list-style-type: disc;
    }
  }
}
::v-deep .el-collapse-item__header {
    font-size: 20px !important;
    font-weight: bold;
  }
</style>
